import React from 'react';
import Icon from '../icon';
import Github from '../icon/github.icon';
import './_footer.scss';

const Footer = ({ setregisterModel }) => (
    <footer className="">
    <div className="get-started">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <h3>Ready to get started <br />with Phasor?</h3>
                </div>
                <div className="col-lg-8 text-right">
                    <div className="inline">
                       
                        <a onClick={() => setregisterModel(true)} className="btn btn-white btn-arrow btn-arrow-white">Apply for Early Access</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="copyright">
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    <div className="inline">
                        <a href="/privacy-policy/">Privacy Policy</a>
                        
                    </div>
                    <p style={{ fontSize:'14px' }}>Phasor is <a href="https://madebyenigma.com"> Made by Enigma</a></p>
                </div>
                <div className="col-md-5">
                    
                </div>
            </div>
        </div>
    </div>
</footer>
);

export default Footer;
