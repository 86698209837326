import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from '../header';
import Footer from '../footer';

import '../../../sass/style.scss';

const TemplateWrapper = ({ children, registerModel, setregisterModel}) => (
    <div>
        <Helmet title="Phasor - Create Backend Faster" >  
            <meta name="description" content="visually create production-ready Backend API & services so that they can build, ship, and scale software faster." />  
        </Helmet>
            <Header />
            <div className="relative" style={ registerModel ? { overflowY:'hidden' } : null }>
            {children}
            </div>
           
            <Footer setregisterModel={setregisterModel} />

       
    </div>
);

TemplateWrapper.propTypes = {
    children: PropTypes.element.isRequired,
};

export default TemplateWrapper;
