import React from 'react';

import './_header.scss';

const Header = () => (
    <header>

        <div class="col">
            <a href="/" class="logo">Phasor</a>
        </div>
    <nav>
        <ul>
            {/* <li class=""><a href="/product/">Product</a></li>
            <li class=""><a href="/security/">About</a></li> */}
          
        </ul>
    </nav>
  
    <a href="#" class="mobile-nav-trigger">
        <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="22" height="1" rx="0.5" fill="#090909"></rect> <rect y="7" width="22" height="1" rx="0.5" fill="#090909"></rect> </svg>
    </a>
    <div class="mobile-nav">
        <div class="mobile-nav-header">
            <a href="/" class="logo"><img src="/img/logo.svg" /></a>
            <a href="#" class="mobile-nav-trigger-close">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1.5752" y="0.368273" width="22" height="1" rx="0.5" transform="rotate(45 1.5752 0.368273)" fill="#090909"></rect> <rect x="0.868164" y="15.9246" width="22" height="1" rx="0.5" transform="rotate(-45 0.868164 15.9246)" fill="#090909"></rect> </svg>
            </a>
        </div>
    </div>
    <div class="backdrop"></div>
</header>
);

export default Header;
